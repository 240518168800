.main-container-partner {
  .custom-fluid {
    padding: 0;
    .imgage-partner-holder {
      img {
        width: 100%;
      }
    }
  }

  .postani-partner {
    position: relative;

    .img-logo-holder {
      position: absolute;
      width: 30%;
      margin-left: 35%;
      top: -280px;

      @media screen and (max-width: 768px) {
        width: 40%;
        margin-left: 30%;
        top: -65px;
      }

      img {
        width: 100%;
      }
    }

    .image-abs {
      img {
        width: 100%;
      }
    }
  }

  .last-image-div {
    img {
      width: 100%;
    }
  }

  .text-holder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #00000085;
    padding: 40px;
    left: 150px;

    @media screen and (max-width: 768px) {
      left: 30px;
      padding: 15px;
    }

    h3 {
      font-family: "Bevan";
      margin: 0;
      color: #fff;
      font-size: 3rem;
      line-height: 4rem;

      @media screen and (max-width: 768px) {
        font-size: 1.4rem;
        line-height: 1.6rem;
      }
    }

    .red-circle-rel {
      position: relative;

      .depth {
        text-shadow: 0 0 2px rgba(255, 255, 255, 0.8), 0 0 0 black;
      }

      .red-circle-abs {
        position: absolute;
        right: -150px;
        display: flex;
        background: #cc0909cc;
        color: #fff;
        padding: 20px 45px;
        text-align: center;
        border-radius: 50%;

        @media screen and (max-width: 768px) {
          right: -60px;
          padding: 5px 25px 10px;
        }

        span {
          font-family: "Bellota Text", cursive;
          font-size: 2.2rem;
          font-weight: 800;
          line-height: 2rem;

          @media screen and (max-width: 768px) {
            font-size: 1.4rem;
            line-height: 1.4rem;
          }
        }
      }
    }
  }

  .asortiman-outer {
    padding: 70px 0;
    background: rgb(243, 220, 0);
    background: linear-gradient(
      157deg,
      rgba(243, 220, 0, 1) 0%,
      rgba(255, 199, 0, 1) 100%
    );
  }

  .div-asortiman {
    p {
      font-family: "Bellota Text", cursive;
      color: #000;
      font-size: 1.2rem;
      font-weight: bold;
      margin: 0;
    }

    h5 {
      font-family: "Bevan", cursive;
      font-size: 1.5rem;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    ul {
      list-style-type: none;

      li {
        font-size: 1.2rem;
        font-family: "Bellota Text", cursive;
        font-weight: bold;
        padding-top: 5px;
        .icon-c {
          margin-right: 8px;
        }
      }
    }
  }
}
