$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

$value1: 576px;

.first-photo-landing-page {
  height: 100vh;
  padding: 0;
  margin: 0;
  font-family: "Bellota";
  position: relative;

  .carousel-second-page-text {
    h2 {
      span.bigger {
        font-family: "Bevan";
        font-size: 5rem;
        color: rgba(0, 0, 0, 0.8);
      }
    }
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center right;
  }

  img.first-photo {
    object-position: bottom center;
  }

  img.photo-align-rign {
    object-position: center center;
  }

  .carousel-second-page-text {
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);

    @media screen and (max-width: 768px) {
      left: 40px;
    }

    h2 {
      font-family: "Bevan";
      font-size: 4rem;
      color: #fff;

      @media screen and (max-width: 768px) {
        font-size: 2.5rem;
      }
    }

    .toburitolink {
      font-family: "Bellota Text", cursive;
      font-size: 2.6rem;
      color: #fff;
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        font-size: 1.8rem;
      }

      &:hover > svg {
        margin-right: 40px;
      }

      svg {
        margin-right: 20px;
        transition: 0.4s all ease;
      }
    }
  }

  img.align-bottom {
    object-position: bottom center;
  }
}

.first-photo-landing-page-car2 {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "Bellota";
  position: relative;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center right;
  }

  img.first-photo {
    object-position: bottom center;
  }

  img.photo-align-rign {
    object-position: center center;
  }

  .carousel-second-page-text {
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);

    @media screen and (max-width: 768px) {
      left: 40px;
    }

    h2 {
      font-family: "Bevan";
      font-size: 4rem;
      color: #fff;

      @media screen and (max-width: 768px) {
        font-size: 2.5rem;
      }
    }

    .toburitolink {
      font-family: "Bellota Text", cursive;
      font-size: 2.6rem;
      color: #fff;
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        font-size: 1.8rem;
      }

      &:hover > svg {
        margin-right: 40px;
      }

      svg {
        margin-right: 20px;
        transition: 0.4s all ease;
      }
    }
  }

  img.align-bottom {
    object-position: bottom center;
  }
}

.custom-align-2 {
  object-position: top center !important;
}

.custom-align-3 {
  object-position: top center !important;
}

.navbar-div {
  //   height: 100px;
}
.real-exp {
  position: absolute;
  left: 50%;
  top: 90px;
  transform: translateX(-50%);
  text-align: center;
  line-height: 1.1rem;

  .mex {
    font-weight: 700;
  }

  @media screen and (max-width: 576px) {
    font-size: 0.75rem;
    line-height: 1rem;
    top: 75px;
  }
}

.second-part-home {
  padding-top: 100px;

  @media screen and (max-width: $value1) {
    padding-top: 30px;
  }

  .rol-image {
    img {
      width: 80%;
      margin-left: 10%;

      @media screen and (max-width: 576px) {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  .text-rol-image {
    margin-top: -20px;
    text-align: center;
    p {
      padding: 0;
      margin: 0;
      font-size: 1rem;
    }

    .first-text {
      color: #e90000;
      font-weight: 500;
    }
  }
}

.third-part {
  text-align: center;
  margin-top: 100px;
  font-family: "Bellota Text", cursive;

  .main-title {
    h2 {
      font-size: 2.6rem;
      font-family: "Bellota Text", cursive;
      span {
        font-weight: 800;
        font-size: 2.4rem;
        font-family: inherit;
      }
    }
  }

  .main-text {
    p {
      font-size: 1.6rem;
      font-weight: 800;
      font-family: "Bellota Text", cursive;
    }
  }
}

.fourth-part {
  padding-top: 200px;
  padding-bottom: 200px;

  @media screen and (max-width: 992px) {
    padding: 100px 0;
    padding-bottom: 20px;
  }

  .pictures-relative {
    position: relative;

    .text-absolute {
      position: absolute;
      height: 280px;
      width: 280px;
      text-align: center;
      vertical-align: middle;
      border-radius: 50%;
      background-color: #cc0909cc;
      z-index: 5;
      padding: 70px 0;
      left: 200px;
      top: -80px;

      @media screen and (max-width: 992px) {
        height: 150px;
        width: 150px;
        left: 150px;
      }

      span {
        display: block;
        margin: auto;
        font-size: 2.8rem;
        font-family: "Bellota Text", cursive;
        font-weight: 800;
        color: #fff;
        @media screen and (max-width: 992px) {
          font-size: 1.7rem;
        }
      }
    }

    .text-meni-lcdb-relative {
      position: relative;
      @media screen and (max-width: 992px) {
        margin-top: -30px;
      }
    }

    .pictures-absolute {
      position: absolute;
      img {
        width: 550px;
        height: 550px;

        @media screen and (max-width: 992px) {
          width: 300px;
          height: 300px;
        }
      }
    }
  }

  .divided-row {
    padding: 30px;

    @media screen and (max-width: 992px) {
      padding-top: 50px;
    }

    text-align: right;
    .title-burrito {
      vertical-align: middle;
      img.burito-icon {
        width: 100px;

        @media screen and (max-width: 992px) {
          width: 60px;
        }
      }

      span.main-title {
        display: inline-block;
        font-size: 2.2rem;
        padding-left: 20px;
        font-weight: 800;
        font-family: "Bellota Text", cursive;
        // margin-top: 55px;

        @media screen and (max-width: 992px) {
          font-size: 2rem;
        }
      }
    }

    .title-divider {
      margin-left: 20%;
      width: 80%;
      border-bottom: 1px solid black;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .main-text {
      p {
        text-align: right;
        font-family: "Bellota Text", cursive;
        font-size: 1.6rem;
        font-weight: 800;
        padding-left: 80px;

        @media screen and (max-width: 992px) {
          font-size: 1.2rem;
          padding-left: 20px;
        }
      }
    }
  }

  .div-2 {
    @media screen and (max-width: 992px) {
      padding-top: 300px;
    }
  }
}

.fifth-part {
  // height: 840px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .carousel-recenzije {
    width: 100%;
    height: 100%;
  }
}

.footer-carousel-div {
  height: 100%;
  .ant-carousel {
  }
  .carousel-recenzije-2 {
    height: 100%;
    .carousel-footer-single-div {
      height: 100%;
      padding: 40px;
      padding-top: 20px;
    }
  }
}

.sixth-part {
  padding-top: 100px;
  font-family: "Bellota Text", cursive !important;

  .divider {
    width: 40%;
    border-bottom: 1px solid black;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .main-title {
    font-family: "Bellota Text", cursive;
    font-weight: 800;
    font-size: 3rem;
    margin-bottom: 5px;
  }

  .about-photo-col {
    img {
      width: 100%;
      object-fit: cover;
      size: 4rem;
      margin-top: 100px;

      @media screen and (max-width: $value1) {
        margin-top: 20px;
      }
    }
  }

  .text-part-1 {
    font-family: "Bellota Text", cursive;
    font-size: 1.3rem;
    font-weight: 500;
  }

  .text-part-2 {
    font-family: "Bellota Text", cursive;
    font-size: 1.3rem;
    font-weight: 500;
  }
}

.seventh-part {
  padding-top: 120px;
  padding-bottom: 120px;
  text-align: center;

  .top-divider {
    border-bottom: 1px solid black;
    width: 70%;
    margin-bottom: 30px;
  }

  .main-text {
    font-family: "Craft" !important;
    font-size: 2rem;
    width: 60%;
    margin-left: 20%;

    @media screen and (max-width: $value1) {
      width: 90%;
      margin-left: 5%;
    }
  }

  .bottom-divider {
    border-bottom: 1px solid black;
    width: 70%;
    margin-top: 30px;
    margin-left: 30%;
  }
}

.partners {
  padding: 100px 0;
  padding-bottom: 140px;

  .main-container {
    position: relative;
  }

  .main-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
    bottom: -20px;
    background-color: #ffffffcc;
    opacity: 0.4;
    z-index: 3;
  }

  .partner-col {
    // padding: 0 100px;
    vertical-align: middle;

    @media screen and (max-width: 1800px) {
      padding: 30px 50px;
    }

    @media screen and (max-width: 1600px) {
      padding: 30px 40px;
    }

    @media screen and (max-width: 1400px) {
      padding: 30px 30px;
    }

    img {
      margin: auto;
      display: block;
      //   vertical-align: middle;
      width: 100%;
    }
  }
}

.main-footer-container {
  min-height: 200px;
  border-top: 3px solid #000;

  .col-footer-first {
    padding: 40px;
    background-color: rgba(0, 0, 0, 0.8);
    min-height: 200px;
    text-align: center;

    h4 {
      font-family: "Bevan", cursive;
      font-weight: 800;
      color: #fff;
      letter-spacing: 4px;
      font-size: 1.6rem;
    }

    .menu-items {
      text-align: left;
      color: black !important;

      .footer-item-divider {
        border-bottom: 1px solid #fff;
        width: 30px;
        transition: 0.3s all ease;
      }

      .menu-item {
        font-family: "Bellota Text", cursive;
        font-size: 1rem;
        font-weight: 500;

        a {
          color: #fff !important;
        }
      }

      .menu-item:hover + .footer-item-divider {
        width: 50px;
      }
    }

    .sm-links {
      text-align: right;
      a {
        color: #fff;
        font-size: 2.2rem;
      }
    }
  }

  .col-footer-second {
    background-color: #ffffffd5;
    min-height: 200px;
    padding: 0px;
    font-family: "Bellota Text", cursive;

    .rec-title {
      font-family: "Bellota Text", cursive;
      font-weight: 800;
      font-size: 1.6rem;
      text-align: center;
      margin-bottom: 10px;
      padding-top: 10px;
    }

    .rec-image-name {
      display: flex;
      margin-bottom: 10px;

      span {
        font-family: "Bellota Text", cursive;
        font-weight: 500;
        font-size: 1rem;
        padding-left: 20px;
      }

      .rec-image {
        img {
          display: block;
          width: 50px;
          height: 50px;
        }
      }
    }

    .divider {
      width: 100%;
      border-bottom: 1px solid black;
    }

    .rec-text {
      margin-top: 10px;
      p {
        font-size: 1.1rem;
        font-family: "Bellota Text", cursive;
        font-weight: 800;
      }
    }
  }

  .col-footer-third {
    // background-color: #ce1127c7;
    background-image: url("../assets/mainpage/posao.jpg");
    background-size: cover;
    min-height: 200px;
    padding: 40px;
    text-align: center;
    padding-top: 90px;

    .link-kolektiv {
      display: block;
      font-size: 1.7rem;
      font-family: "Bevan";
      font-weight: 500;
      font-style: italic;
    }

    .link-kolektiv-2 {
      display: block;
      font-size: 1.5rem;
      font-family: "Bevan";
      font-weight: 500;
      font-style: italic;
    }
  }

  a {
    color: #fff;
  }

  a:hover {
    color: #fff;
  }

  .link-kolektiv-divider {
    border-bottom: 2px solid white;
    width: 50%;
    margin-left: 25%;
  }

  @media screen and (max-width: 1200px) {
    .col-footer-third,
    .col-footer-first {
      background: none;
    }

    .main-footer-container {
      // background-image: url("../assets/mainpage/flag.svg");
      // background-size: cover;
      // background-position: center center;
      // background-repeat: no-repeat;
      background-color: #090c02;
    }
  }
}

@media screen and (max-width: 1200px) {
  .main-footer-container {
    // background-image: url("../assets/mainpage/flag.svg");
    // background-position: center center;
    // background-repeat: repeat-y;
    background-color: #090c02;
  }
}

.how-its-made {
}

.custom-comp-carousel {
  height: 80px;
  margin-top: 50px;
  margin-bottom: 100px;
}

// .rec-arrow-right {
//   display: none;
// }

// .rec-arrow-left {
//   display: none;
// }

.main-carousel-div-holder {
  margin-top: 50px;
  margin-bottom: 100px;
}

// .global-slider {
//   height: 200px;
//   display: grid;
//   place-items: center;

//   .slider {
//     height: 150px;
//     margin: auto;
//     position: relative;
//     width: 90%;
//     display: grid;
//     place-items: center;
//     overflow: hidden;

//     .slider-track {
//       display: flex;
//       width: calc(100%);
//       justify-content: space-between;
//       // animation: scroll 20s linear infinite;

//       .partner-col {
//         height: 150px;
//         width: 250px;
//         align-items: center;
//         display: flex;
//         padding: 15px;

//         img {
//           height: 100%;
//           // width: 100%;
//           object-fit: cover;
//         }
//       }
//     }
//   }
// }

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 8));
  }

  0% {
    transform: translateX(0);
  }
}

.steps-ingredients {
  margin-top: 60px;

  .list-of-steps {
    margin-bottom: 50px;

    h4 {
      font-family: "Bevan";
      font-size: 1.6rem;
    }

    .description-of-step {
      font-family: "Bellota Text";
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
}

.step-divider {
  border: 1px solid black;
  width: 20%;
  margin-left: 40%;
  margin-bottom: 10px;
}

h4.name-of-step-last {
  padding-top: 30px;
  font-family: "Bevan";
  font-size: 1.6rem;
}

@media screen and (min-width: 992px) {
  .global-slider {
    height: 150px;
    .partner-col {
      height: 150px;
      img {
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.slider-magacin-div {
  @media screen and (max-width: 992px) {
    display: none !important;
  }
}

.main-div-job-contact {
  min-height: 80vh;
}

.burito-link-div {
  position: absolute;
  left: 50%;
  top: 55px;
  transform: translateX(-50%);
  button {
    span {
      font-family: "Bevan";
      font-size: 2rem;
      letter-spacing: 2px;

      &:nth-child(1) {
        font-size: 1.4rem;
      }

      &:nth-child(2) {
        font-size: 2.6rem;
      }
    }
    span.bold-text {
      font-size: 2.2rem;
      padding-top: 10px;
    }
  }
}

.img-of-magacin-div {
  width: 100%;
  height: 150px;
  background-color: rgba(0, 0, 0, 0.86);
  img {
    width: 100%;
    height: 150px;
    object-fit: contain;
  }
}

.middlephoto-custom-4 {
  object-position: top center !important;
}

@media screen and (max-width: 768px) {
  .middlephoto-custom-1 {
    object-position: center center !important;
  }
  .middlephoto-custom-2 {
    object-position: center !important;
  }
  .middlephoto-custom-3 {
    object-position: center center;
  }
  .middlephoto-custom-4 {
    object-position: center center;
  }
}
