.main-div-magacin {
  min-height: 100vh;
  background-color: #000;
  position: relative;

  .rolling-bar {
    position: absolute;
    width: 100%;
    height: 13vh;
    background-color: #ffffff99;
  }

  .main-div-logo {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);

    @media screen and (max-width: 768px) {
      left: 0;
      transform: none;
    }

    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;

      @media screen and (max-width: 768px) {
        object-fit: contain;
      }
    }
  }
}

.burger-div-1 {
  background: url("../assets/magacin/magacinburger.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  padding-top: 50px;
  position: relative;
  z-index: -4;

  .for-bg {
    position: absolute;
    display: block;
    left: 0;
    right: 150px;
    top: 150px;
    bottom: 170px;
    z-index: -1;
    background-color: rgba(163, 163, 163, 0.479);

    @media screen and (max-width: 768px) {
      top: 180px;
      right: 40px;
    }
  }

  .inner-magacin-orange-div {
    background-color: #ff6b0080;
    margin-top: 80px;
    padding-top: 30px;
    margin-right: 100px;
    margin-left: 50px;
    z-index: 12;

    @media screen and (max-width: 768px) {
      margin-top: 30px;
      margin-right: 20px;
      margin-left: 10px;
    }
  }

  .align-right {
    text-align: right;
    font-size: 3rem;

    @media screen and (max-width: 768px) {
      br {
        display: none;
        text-align: left;
        font-weight: bold;
      }
    }
  }

  .text-magacin {
    font-size: 1.8rem;
    p {
      font-family: "Bellota Text", cursive;
      font-weight: 800;

      @media screen and (max-width: 768px) {
        font-size: 1.2rem;
      }
    }
  }

  .text-div {
    text-align: right;

    h3,
    h6 {
      font-family: "Bellota Text", cursive;
      font-weight: 1000;
    }

    h3 {
      font-size: 2.2rem;
      margin-bottom: 5px;
      font-weight: 1000;
      color: rgb(228, 228, 228);
    }

    h6 {
      font-size: 1.2rem;
      margin-top: 5px;
      font-weight: 1000;
      color: rgb(228, 228, 228);
    }

    .magacin-divider {
      border-bottom: 2px solid black;
      width: 40%;
      margin-left: 60%;
    }
  }
}

.burger-page-div-2 {
  width: 100%;
  position: relative;
  min-height: 150vh;
  background: url("../assets/magacin/magacinburger2.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  .text-over-burger {
    padding-top: 300px;
    margin-left: 200px;
    @media screen and (max-width: 768px) {
      margin-left: 30px;
    }

    h1,
    h2 {
      font-family: "Bevan", cursive;
    }

    h1 {
      font-size: 4.6rem;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }
    }
    .white {
      color: #fff;
    }

    a {
      color: #000;
      font-family: "Bevan", cursive;
    }
  }
}

.partner-magacin {
  padding: 0;
}

.main-overlay {
  background-color: #fff;
}

.container-magacin-overlay {
  background-color: rgba(187, 187, 187, 0.432);
}

.col-magacin {
  padding: 20px;
  img {
    width: 100%;
    mix-blend-mode: soft-light;
  }
}
