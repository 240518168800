$bright-yellow: #f2e24e;

.main-menu-page {
  background-color: #f3dc0ce5;
}

.main-landing-page-bur {
  .burito-page-first-div {
    height: 90vh;
    background-color: $bright-yellow;
    position: relative;
    z-index: 3;

    .overlay-black {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: #000;
      opacity: 0.4;
      z-index: -1;

      @media screen and (min-width: 992px) {
        display: none !important;
      }
    }

    .custom-container-width {
      max-width: 1300px;
    }

    .text-cont {
      width: 25%;
      text-align: left;
      padding-top: 200px;
      z-index: 4;

      @media screen and (max-width: 992px) {
        width: 90%;
      }

      p {
        font-family: "Bellota Text", cursive;
        font-size: 2rem;
        font-weight: bold;
        text-shadow: 0 0 1px black;
        z-index: 5;
        margin-bottom: 50px;
        line-height: 2.4rem;

        @media screen and (max-width: 992px) {
          color: #fff;
          font-size: 2.2rem;
        }
      }

      .text-bevan {
        p {
          font-family: "Bevan";
          text-shadow: none;
          letter-spacing: 2px;

          @media screen and (max-width: 992px) {
            color: #fff;
            font-size: 2.6rem;
            letter-spacing: 4px;
          }
        }
      }

      .btn-cont {
        button {
          background-color: #cc0909cc;
          color: #fff;
          font-family: "Bellota Text", cursive;
          font-weight: 800;
          font-size: 2rem;
          padding: 5px 30px;
          border-radius: 15px;
          border: none;
          line-height: 2.6rem;

          &:focus {
            border: none;
          }
        }
      }
    }

    .img-container-landing {
      position: absolute;
      width: 61%;
      right: 0;
      top: 0;
      z-index: -2;

      @media screen and (max-width: 992px) {
        width: 100%;
      }

      img {
        width: 100%;
        object-fit: cover;
        z-index: 5;

        @media screen and (max-width: 992px) {
          height: 90vh;
          object-fit: cover;
        }
      }
    }
  }

  .black-bg {
    min-height: 25vh;
    background-color: black;
    text-align: center;
    display: flex;
    align-items: center;
    position: relative;

    @media screen and (max-width: 768px) {
      min-height: 30vh;
    }

    @media screen and (max-width: 576px) {
      min-height: 35vh;
      align-items: start;
    }

    h4 {
      color: #fff;
      font-family: "Bellota Text", cursive;
      font-size: 2.8rem;
      margin: 0;
      z-index: 1000;

      @media screen and (max-width: 576px) {
        font-size: 2.5rem;
        padding-top: 50px;
      }

      span.span-another-font {
        font-family: "Bevan";
      }
    }

    .logo-white {
      position: absolute;
      right: 20px;
      bottom: 20px;
      z-index: 20;

      img {
        height: 60px;
        object-fit: cover;
      }
    }
  }
}

.main-middle-yellow {
  padding-top: 50px;
  padding-bottom: 50px;
  .headline-1 {
    font-size: 2rem;
    font-family: "Bellota Text", cursive;
    min-height: 2rem;

    h2 {
      font-size: 2rem;
      font-family: "Bellota Text", cursive;
      min-height: 5.4rem;
      margin-bottom: 0;
    }
  }

  .head-p {
    padding-left: 10%;
  }

  .divider-headline-1 {
    border-bottom: 1px solid black;
    margin-bottom: 20px;
  }

  .head-1 {
    width: 80%;
  }

  .head-k {
    display: block;
  }

  .head-2 {
    width: 90%;
    margin-left: 10%;
  }

  p {
    font-weight: 800;
    font-size: 1rem;
    font-family: "Bellota Text", cursive;
  }

  .main-divider {
    border-bottom: 1px solid black;
    width: 50%;
    margin-left: 25%;
  }

  .main-text {
    padding: 20px;
  }
  .flex-col-ukoliko {
    display: flex;

    p {
      margin-top: 15px;
      margin-left: 50%;
      font-size: 0.8rem;
      font-weight: 800;
      width: 50%;
      line-height: 1.1rem;
      text-align: right;
    }
  }

  .main-text-2 {
    border-right: 1px solid black;
  }

  h2.h2-sd {
    height: 1rem;
    text-align: center;
  }
}

.main-main {
  background: #e1dcdc;
  padding-top: 30px;

  .main-container-jelovnik {
    .list-of-d {
      text-align: center;
      padding-top: 40px;
      padding-bottom: 20px;

      @media screen and (max-width: 768px) {
        padding-top: 10px;
      }

      span {
        font-family: "Bevan";
        font-size: 1.45rem;
        line-height: 1rem;

        @media screen and (max-width: 768px) {
          font-size: 1.55rem;
          line-height: 2.2rem;
        }

        span.smaller {
          font-size: 0.8rem;

          @media screen and (max-width: 768px) {
            line-height: 1rem;
          }
        }
      }
    }
  }

  .main-container-dish-single {
    width: 100%;
    padding: 30px;
    padding-bottom: 0;
    padding-top: 0;

    @media screen and (max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    .img-of-dish {
      height: 500px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .img-overlay-right {
      position: absolute;
      left: 20px;
      top: 20px;
      bottom: 20px;
      right: 0;
      background-color: #f2e24e;
      opacity: 0.2;

      @media screen and (max-width: 768px) {
        left: 0px;
        top: 20px;
        bottom: 20px;
        right: 20px;
        opacity: 0.4;
      }
    }

    .orange-text {
      background-color: #ffc700;
      padding: 50px;
      text-align: center;

      @media screen and (max-width: 768px) {
        padding: 15px;
      }

      p {
        font-family: "Bellota Text", cursive;
        font-size: 1.3rem;
        font-weight: 800;
        @media screen and (max-width: 768px) {
          font-size: 1rem;
        }
      }

      .jelovnik-btn {
        text-align: right;
        padding-top: 50px;

        button {
          font-family: "Bellota Text", cursive;
          font-size: 1.6rem;
          font-weight: 800;
          padding: 14px 45px;
          border: none;
          border-radius: 15px;
          box-shadow: 0 0 10px #0000005e;
          background-color: #0000008c;
          color: #fff;
          align-items: center;

          &:focus {
            outline: none !important;
          }

          span {
            padding-left: 8px;
            font-family: "Bellota Text", cursive;
            font-size: 1.6rem;
            font-weight: 800;
          }
        }
      }
    }
  }

  .text-on-image {
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translateY(-50%);

    @media screen and (max-width: 768px) {
      top: 10%;
      transform: none;
    }

    h3 {
      font-family: "Bevan";
      color: #fff;
      font-size: 3.4rem;
      margin-bottom: 4rem;
      line-height: 5rem;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }

      .black-s {
        font-family: "Bevan";
        color: #000;
        font-size: 3.4rem;
        margin-bottom: 4rem;
      }
    }

    span {
      font-family: "Bellota Text", cursive;
      color: #fff;
      font-size: 1.5rem;
      line-height: 1.4rem;
      font-weight: 100;
    }

    .black-text-bellow-name {
      color: #000;
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 2.1rem;
    }
  }

  span.green-text {
    font-family: "Bevan";
    color: #2ba600;
    font-size: 3.4rem;
    margin-bottom: 4rem;
  }
  span.salata-text {
    font-weight: bold;
    line-height: 1.8rem;
  }
}

.locallink {
  color: #000;
  transition: all 0.3s ease;

  &:hover {
    color: #000;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.753);
    padding-left: 10px;
  }
}

.modal-zaposleni {
  background: url("../assets/buritopage/zaposljeni.jpg");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f4df25d8;
  top: 200px;
  padding-bottom: 0;
  border: 2px solid #f4e025;

  .ant-modal-content {
    border-radius: 0;
    background: transparent;
    text-align: center;

    .ant-modal-body {
      color: #fff !important;

      h3,
      h4,
      h5 {
        color: #fff;
        font-family: "Bellota Text";
        font-weight: 500;

        span {
          font-family: "Bevan";
        }
      }

      h3 {
        font-family: "Bevan";
        letter-spacing: 2px;
      }

      h5 {
        a {
          margin-left: 10px;
          color: #fff;
          letter-spacing: 2px;
        }
      }
    }
  }

  .ant-modal-footer {
    text-align: center;
    border-color: #000;

    button:nth-child(1) {
      background-color: rgba(0, 0, 0, 0.685);
      color: #f4e025;
      border-radius: 0;
      border: none;
      box-shadow: none;
      font-family: "Bellota Text";
    }

    button:nth-child(2) {
      background-color: rgba(0, 0, 0, 0.95);
      color: #f4e025;
      border-radius: 0;
      border: none;
      box-shadow: none;
      font-family: "Bellota Text";
    }
  }
}
